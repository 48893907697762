@font-face {
font-family: '__sfProDisplay_cc0bcb';
src: url(/_next/static/media/7b6fea621790da4d-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__sfProDisplay_cc0bcb';
src: url(/_next/static/media/0fe7ec85885462b1-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__sfProDisplay_cc0bcb';
src: url(/_next/static/media/d444cb7ee49237c9-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__sfProDisplay_cc0bcb';
src: url(/_next/static/media/f15837ddf307a9c9-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__sfProDisplay_cc0bcb';
src: url(/_next/static/media/3799ab9c6ac6aa0f-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 800;
font-style: normal;
}@font-face {font-family: '__sfProDisplay_Fallback_cc0bcb';src: local("Arial");ascent-override: 101.52%;descent-override: 25.72%;line-gap-override: 0.00%;size-adjust: 93.79%
}.__className_cc0bcb {font-family: '__sfProDisplay_cc0bcb', '__sfProDisplay_Fallback_cc0bcb'
}

